import React, { useState } from 'react';
import './App.css';
import Header from "./components/Header";
import FirstSection from './components/FirstSection.jsx';
import AboutSection from './components/AboutSection.jsx';
import NewSection from './components/NewSection.jsx';
import SecondSection from './components/SecondSection.jsx';
import Footer from './components/Footer.jsx';
import SplashScreen from './components/SplashScreen.jsx';
import song from "./songs/1.mp3"

function App() {
  const [isLoading, setIsLoading] = useState(true);

  const handleSplashFinish = () => {
    // Reproducir la canción al hacer clic en la pantalla de splash
    const audio = new Audio(song); // Asegúrate de que esta ruta sea correcta
    audio.play();
    setIsLoading(false);
  };

  if (isLoading) {
    return <SplashScreen onFinish={handleSplashFinish} />;
  }

  return (
    <div className="App">
      <Header />
      <div className="firstSection">
        <FirstSection />
        <SecondSection />
        <AboutSection />
        <NewSection />
        <Footer />
      </div>
    </div>
  );
}

export default App;
