import React from 'react';
import './SplashScreen.css';

const SplashScreen = ({ onFinish }) => {
  const handleClick = () => {
    onFinish();
  };

  return (
    <div className="splash-screen" onClick={handleClick}>
      <h1>Welcome to Freddy Krueger realm</h1>
      <p>Click anywhere to enter the nightmare</p>
    </div>
  );
};

export default SplashScreen;
