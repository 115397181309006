import React from 'react';
import './Footer.css';
import logo from "../images/banner.png"

const Footer = () => {
  return (
    <footer className="footer">
      <div className="logo">
        <img src={logo} alt="footer-logo" />
      </div>
      <div className="menu">
        <a href="https://x.com/freddykruegerv" target='_blank' rel="noopener noreferrer">Twitter</a>
        <a href="https://t.me/freddykruegersol" target='_blank' rel="noopener noreferrer">Telegram</a>
        <a href="https://dexscreener.com/new-pairs" target='_blank' rel="noopener noreferrer">Chart</a>
      </div>
    </footer>
  );
};

export default Footer;
