import React from 'react';
import './NewSection.css'; 
import logo from "../images/microwavefire.gif"; 

const NewSection = () => {
  return (
    <section className="new-section">
      <div className="content">
        <div className="text">
          <h2>pump.fun fairlaunch</h2>
          <p> Freddy Krueger slashes through the ADA blockchain, turning smart contracts into nightmares. </p>
          <p> Gas fees rise with every scream, as he haunts the digital realm. </p>
          <p> In this ledger, no wallet is safe from Freddy’s burning claws</p>
        </div>
        <div className="image">
          <img src={logo} alt="Example" />
        </div>
      </div>
    </section>
  );
};

export default NewSection;
